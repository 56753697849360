import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Toasted from 'vue-toasted';


/* Styles ---------------------------------------------------------------------- */
import '@/assets/css/style.css'
import '@/assets/css/component.scss'
/* ----------------------------------------------------------------------------- */

/**
 * Axios Configs ---------------------------------------------------------------
 */
axios.defaults.withCredentials = true
axios.interceptors.request.use(async function (config) {
  config.headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  config.baseURL = process.env.VUE_APP_API_URL
  return config;
})
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      router.replace({ name: 'Login' })
    }
    return Promise.reject(error);
  })
// -----------------------------------------------------------------------------

/* FontAwesome ----------------------------------------------------------------- */
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp, faBars, faCheck, faDownload, faEllipsisH, faGraduationCap, faHistory,
  faListUl, faMedal, faPencilAlt, faPlus, faPlusCircle, faPrint, faSave, faSearch, faTimes, faTrashAlt,
  faUpload, faUser, faUserCheck, faUserEdit, faUserSlash, faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faGraduationCap, faEllipsisH, faBars, faUser, faListUl,
  faMedal,
  /* Form Actions */
  // CRUDs
  faTimes, faPlusCircle, faTrashAlt, faPencilAlt,
  faCheck, faUpload, faSave, faPlus, faDownload,

  // Users
  faUserSlash, faUserEdit, faUserCheck,
  faEye, faEyeSlash,
  faHistory, faAngleUp, faAngleDown, faAngleLeft, faAngleRight, faSearch, faPrint
);

Vue.component('fa-icon', FontAwesomeIcon)
/* ----------------------------------------------------------------------------- */

/* Vue ------------------------------------------------------------------------- */
Vue.config.productionTip = false
Vue.use(Toasted, { duration: 5000 });

// Directives
// https://forum.vuejs.org/t/auto-focus-on-input-generated-by-v-if/19978/4
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: (el, binding) => {
    if (binding.value === true) {
      el.focus() // Focus the element
    }
  }
})

// Filters
Vue.filter('only_year', (value) =>
  value ? new Date(value).getFullYear() : '...'
);
Vue.filter('indonesian_date_format', (value) =>
  value ? (new Date(value)).toLocaleDateString('id-ID') : '...'
);
Vue.filter('indonesian_datetime_format', (value) =>
  value ? (new Date(value)).toLocaleString('id-ID') : '...'
);
Vue.filter('indonesian_number_format', (value = '...') =>
  isNaN(value) ? value : Intl.NumberFormat('id-ID').format(value)
);
Vue.filter('international_phone_format', (value = null) =>
  value ? value.replace(/^0/, '62').replace(/[\W_]/g, '') : value)

Vue.filter('time_only', (value) =>
  value ? new Date(value).toLocaleTimeString([], {
    hour: '2-digit', minute: "2-digit", hour12: false
  }) : '...')

store.dispatch('auth/me').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})