import Vue from 'vue'
import _ from 'lodash'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    form_visibility: false,
    selected_form: null,
    students: [],
    links: [],
    selected_student: null
  },
  getters: {
    students: state => state.students,
    links: state => state.links,
    form_visibility: state => state.form_visibility,
    selected_student: state => state.selected_student,
    selected_form: state => state.selected_form
  },
  mutations: {
    RESET(state) {
      state.form_visibility = false
      state.students = []
      state.selected_student = null
    },
    INIT_LNKS(state, items) {
      state.links = items
    },
    INDEX_STUDENT(state, items) {
      state.students = items
    },
    STORE_STUDENT(state, item) {
      state.students.unshift(item)
    },
    UPDATE_STUDENT(state, item) {
      let targetIndex = _.findIndex(state.students, { id: item.id })

      /** Keep the relationship intact */
      let relationshipAddress = state.students[targetIndex]['address']
      let relationshipParent = state.students[targetIndex]['parents']
      item.address = relationshipAddress
      item.parents = relationshipParent

      Vue.set(state.students, targetIndex, item)
    },
    UPDATE_ADDRESS(state, item) {
      let targetIndex = _.findIndex(state.students, { id: item.student_id })
      let student = state.students[targetIndex];
      student.address = item
      Vue.set(state.students, targetIndex, student)
    },
    UPDATE_PARENT(state, item) {
      let targetIndex = _.findIndex(state.students, { id: item.student_id })
      let student = state.students[targetIndex];
      let parentIndex = _.findIndex(student.parents, { id: item.id })
      student.parents[parentIndex] = item
      Vue.set(state.students, targetIndex, student)
    },
    DELETE_STUDENT(state, item) {
      let targetIndex = _.findIndex(state.students, { id: item.id })
      Vue.delete(state.students, targetIndex)
    },
    SET_SELECTED_STUDENT(state, item) {
      state.selected_student = item
    },
    UNSET_SELECTED_STUDENT(state) {
      state.selected_student = null
    },
    SHOW_FORM(state, form) {
      state.form_visibility = true
      state.selected_form = form
    },
    HIDE_FORM(state) {
      state.form_visibility = false
      state.selected_student = null
      state.selected_form = null
    }
  },
  actions: {
    /** Indexing students */
    async fetchStudents({ commit }, link = null) {
      try {
        let targetLink = link || 'api/student';
        let response = await axios.get(targetLink)
        if (!response.data.student) {
          return
        }

        const relationshipsExist = response.data.student.every(i => i.address && i.parents)
        if (!relationshipsExist) {
          Vue.toasted.error("Data ERROR: Referensi informasi Alamat atau Orang Tua beberapa peserta didik tidak ditemukan");
          return
        }

        commit('INDEX_STUDENT', response.data.student)
        commit('INIT_LNKS', response.data.meta.links)
      } catch (error) {
        Vue.toasted.error(error.response.data.message)
      }
    },

    /** Store single student */
    async storeStudent({ commit }, item) {
      try {
        let response = await axios.post('api/student', item)
        commit('STORE_STUDENT', response.data.student)
        Vue.toasted.success('Pendaftaran awal peserta didik berhasil')
      } catch (error) {
        if (error.response !== undefined) {
          Vue.toasted.error(error.response.data.message)
        } else {
          Vue.toasted.error('Pendaftaran awal peserta didik gagal')
        }
      }
    },

    /** Update single student */
    async updateStudentSchedule(context, item) {
      try {
        await axios.put(`api/student/schedule-time/${item.id}`, item)
        // commit('UPDATE_STUDENT', response.data.student)
        Vue.toasted.success('Pengeditan waktu tes peserta didik berhasil')
      } catch (error) {
        error
        Vue.toasted.error('Pengeditan waktu tes peserta didik gagal.')
      }
    },

    /** Update single student */
    async updateStudent({ commit }, item) {
      try {
        let response = await axios.put(`api/student/information/${item.id}`, item)
        commit('UPDATE_STUDENT', response.data.student)
        Vue.toasted.success('Pengeditan peserta didik berhasil')
      } catch (error) {
        if(error.response.status === 422) {
          error.response.data.message.forEach(message => {
            Vue.toasted.error(message)
          });
        } else {
          Vue.toasted.error('Pengeditan peserta didik gagal. Mohon periksa kembali kelengkapan pengisian data')
        }
      }
    },

    /** Update student address */
    async updateAddress({ commit }, item) {
      try {
        let response = await axios.put(`api/student/address/${item.id}`, item)
        commit('UPDATE_ADDRESS', response.data.student_address)
        Vue.toasted.success('Pengeditan alamat peserta didik berhasil')
      } catch (error) {
        Vue.toasted.error('Pengeditan alamat peserta didik gagal. Mohon periksa kembali kelengkapan pengisian data')
      }
    },

    /** Update student parent */
    async updateParent({ getters, commit }, item) {
      let maps = { father: "ayah", mother: "ibu", guardian: "wali" };
      let formType = maps[getters.selected_form]

      try {
        let response = await axios.put(`api/student/parent/${item.id}`, item)
        commit('UPDATE_PARENT', response.data.student_parent)
        Vue.toasted.success(`Pengeditan informasi ${formType} peserta didik berhasil`)
      } catch (error) {
        Vue.toasted.error(`Pengeditan informasi ${formType} peserta didik gagal. Mohon periksa kembali kelengkapan pengisian data`)
      }
    },

    /** Delete single student */

    /** Get Excel of accepted stutent at selected year */
    async downloadExcel() {
      await axios.get('api/student/accepted/excel', {
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
      })
    }
  }
}