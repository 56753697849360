<template>
  <div class="form mb-12">
    <form action="#" @submit.prevent="triggerFetchCandidate()">
      <div class="inline-block">
        <label for="name" class="uppercase text-sm block">Nama</label>
        <input
          class="inline-block"
          type="text"
          id="period-name"
          placeholder="Cari nama calon peserta didik"
          v-model="q"
        />
      </div>

      <div class="inline-block mx-4">
        <label for="name" class="uppercase text-sm block">Tahun Daftar</label>
        <select
          v-model="filter.year"
          name=""
          id=""
          placeholder="Tahun Daftar"
          class="p-2"
        >
          <option value="">TERBARU</option>
          <option value="all">SEMUA Tahun</option>
          <option v-for="year in years" :value="year" :key="`year-${year}`">
            {{ year }}
          </option>
        </select>
      </div>
      <button class="button --primary inline-block ml-4" type="submit">
        <fa-icon icon="search"></fa-icon>
      </button>
      <button class="button --primary ml-1" @click="triggerShowAll()">
        <fa-icon icon="history"></fa-icon>
        <span class="ml-2 uppercase tracking-wider">reset</span>
      </button>
      <!-- Excel Trigger -->
      <a v-if="q === ''" :href="excelUrl" @click="showLoadingNotification('export Excel')" class="button --primary ml-1">
        <fa-icon icon="download"></fa-icon>
        Excel
      </a>
      <!-- PDF Trigger -->
      <a v-if="q === ''" :href="pdfUrl" @click="showLoadingNotification('export PDF')" class="button --primary ml-1">
        <fa-icon icon="download"></fa-icon>
        PDF
      </a>

    </form>
  </div>
</template>

<style lang="scss" scoped>
.form {
  input,
  label {
    @apply block;
  }
}
</style>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      q: "",
      years: [],
      filter: {
        year: ''
      }
    };
  },
  methods: {
    async triggerShowAll() {
      this.q = "";
      this.filter.year = '';

      this.triggerFetchCandidate();
    },


    async triggerFetchCandidate() {
      let filter = `?q=${this.q}&target_year=${this.filter.year}`;
      await this.fetchCandidates(filter);
    },

    showLoadingNotification(action = '') {
      this.$toasted.info('Sedang memproses permintaan ' + action)
    },

    ...mapActions("candidate", ["fetchCandidates"]),
  },
  computed: {
    excelUrl() {
      let url = process.env.VUE_APP_API_URL + "/api/student/candidate/excel";
      let yearFilter = this.filter.year
        ? `?target_year=${this.filter.year}`
        : "";
      return url + yearFilter;
    },
    pdfUrl() {
      let url = process.env.VUE_APP_API_URL + "/api/student/candidate/pdf";
      let yearFilter = this.filter.year
        ? `?target_year=${this.filter.year}`
        : "";
      return url + yearFilter;
    },
  },
  name: "SearchForm",
  mounted() {
    for (let i = new Date().getFullYear(); i >= 2010; i--) {
      this.years.push(i);
    }
  },
};
</script>