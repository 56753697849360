<template>
  <div class="relative z-20">
    <div class="inline-block">
      <label for="name" class="uppercase text-sm block">Nama</label>
      <input
        class="w-full"
        id="name"
        v-model="name"
        type="text"
        placeholder="nama peserta didik"
      />
    </div>

    <div class="inline-block mx-4">
      <label for="name" class="uppercase text-sm block">Angkatan</label>
      <select
        v-model="accepted_year"
        name=""
        id=""
        placeholder="Angkatan"
        class="p-2"
      >
        <option value="">TERBARU</option>
        <option value="all">SEMUA ANGKATAN</option>
        <option v-for="year in years" :value="year" :key="`year-${year}`">
          {{ year }}
        </option>
      </select>
    </div>
    <div class="inline-block">
      <button class="button --primary ml-1" @click="triggerSearch()">
        <fa-icon icon="search"></fa-icon>
        <span class="ml-2">Cari</span>
      </button>
      <button class="button --primary ml-1" @click="triggerShowAll()">
        <fa-icon icon="history"></fa-icon>
        <span class="ml-2 uppercase tracking-wider">reset</span>
      </button>
      <!-- Excel Trigger -->
      <a v-if="name === ''" :href="excelUrl" @click="showLoadingNotification('export Excel')" class="button --primary ml-1">
        <fa-icon icon="download"></fa-icon>
        Excel
      </a>
      <!-- PDF Trigger -->
      <a v-if="name === ''" :href="pdfUrl" @click="showLoadingNotification('export PDF')" class="button --primary ml-1">
        <fa-icon icon="download"></fa-icon>
        PDF
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      name: "",
      accepted_year: "",
      years: [],
      isProcessing: {
        excel: false,
        pdf: false
      }
    };
  },
  computed: {
    excelUrl() {
      let url = process.env.VUE_APP_API_URL + "/api/student/accepted/excel";
      let yearFilter = this.accepted_year
        ? `?target_year=${this.accepted_year}`
        : "";
      return url + yearFilter;
    },
    pdfUrl() {
      let url = process.env.VUE_APP_API_URL + "/api/student/accepted/pdf";
      let yearFilter = this.accepted_year
        ? `?target_year=${this.accepted_year}`
        : "";
      return url + yearFilter;
    },
  },

  methods: {
    triggerShowAll() {
      this.name = "";
      this.accepted_year = "";

      this.triggerSearch();
    },

    triggerSearch() {
      this.$emit("student-searched", {
        q: this.name,
        targetYear: this.accepted_year,
      });
    },

    showLoadingNotification(action = '') {
      this.$toasted.info('Sedang memproses permintaan ' + action)
    },

    async triggerDownloadExcel() {
      await this.downloadExcel();
    },
    ...mapActions("student", ["downloadExcel"]),
  },
  mounted() {
    for (let i = new Date().getFullYear(); i >= 2010; i--) {
      this.years.push(i);
    }
  },
  name: "StudentFilterForm",
};
</script>